<template>
  <div>
    <div class="row">
      <div class="col">
        <button
          class="btn btn-success"
          @click="
            $refs.elModal.show();
            $refs.elForm.reset();
          "
        >
          <i class="fa fa-plus"></i> Nueva tarea de contacto
        </button>
      </div>
    </div>

    <h4>TAREAS DE CONTACTO</h4>
    <ContactTaskList :client_id="client_id"></ContactTaskList>

    <h4>COMENTARIOS ACERCA DEL PACIENTE</h4>
    <app-comments :url="`dental_client_${client_id}`"></app-comments>
    <app-modal-basic ref="elModal">
      <ContactTaskForm
        ref="elForm"
        :clientId="this.client_id"
        @submitted="$refs.elModal.hide()"
      ></ContactTaskForm>
    </app-modal-basic>
  </div>
</template>
<script>
import ContactTaskForm from "../contact-tasks/Form";
import ContactTaskList from "../contact-tasks/List";

export default {
  components: {
    ContactTaskForm,
    ContactTaskList
  },

  // directives
  // filters

  props: {
    client_id: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
