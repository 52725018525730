<template>
  <div>
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th>FECHA</th>
          <th>REALIZADO</th>
          <th>ESTADO</th>
          <th>COMENTARIO</th>
          <th>TIPO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>{{ l.date_to_contact | date }}</td>
          <td>{{ l.maked ? "si" : "no" }}</td>
          <td>{{ statusDesc(l.status_id) }}</td>
          <td>{{ l.note }}</td>
          <td>
            <spanType :typeId="l.type_id"></spanType>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import spanType from "./spanType";
import { DentalClinicService } from "../service";

export default {
  components: {
    spanType
  },

  // directives
  // filters

  props: {
    client_id: {}
  },

  data: () => ({
    list: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      DentalClinicService.getContactTasks({
        client_id: this.client_id
      }).then(list => {
        this.list = list;
      });
    },
    statusDesc(status_id) {
      switch (status_id) {
        case 1:
          return "Pendiente";
        case 2:
          return "Citado";

        case 3:
          return "Reprogramado";

        case 4:
          return "Caso Cerrado";

        default:
          break;
      }
      return "";
    }
  }
};
</script>

<style></style>
